@import '../../../node_modules/@livingsecurity/cyberblocks/dist/styles/mixins.scss';
@import '../../../node_modules/@livingsecurity/cyberblocks/dist/styles/variables.scss';
@import '../../../node_modules/@livingsecurity/cyberblocks/dist/styles/app.css';
@import '../../../node_modules/@livingsecurity/cyberpuzzles/dist/styles/index.css';
@import 'fontawesome-all.min.css';

/* Base styles */
* {
  font-family: 'Gotham', Arial, Helvetica, sans-serif;
  box-sizing: border-box !important;
}

body {
  width: 100vw;
  min-width: 320px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  color: black;
  background: black !important;
  overflow-x: hidden;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;

  h1,
  h2,
  h3,
  h4 {
    color: white;
    cursor: default;
  }

  button {
    outline: none !important;

    &:focus {
      outline: none !important;
    }
  }

  &.modal-open {
    overflow: hidden;
  }

  .background-layout {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(https://cdn.cyberescape.livingsecurity.com/image/BGimage_4K.jpg);
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    background-position: left bottom;
  }

  .reactour__helper {
    border-radius: 10px;
    color: #000000;
    font-size: 14px;

    div:nth-child(3),
    .bjanEz {
      justify-content: center;
    }
  }

  .reactour__close {
    top: 10px;
    right: 10px;
  }

  .ls-button {
    background: #2d9cdb;
  }
}

ol,
ul {
  list-style: none;
}

li {
  list-style: none;
}

svg {
  pointer-events: none;
  z-index: 1;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  outline: none;

  &:hover,
  &:link,
  &:active,
  &:visited {
    outline: none !important;
  }

  &:hover {
    text-decoration: none;
  }
}

input,
textarea,
select {
  outline: none !important;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='text'] {
  -moz-appearance: textfield;

  &::-ms-clear {
    display: none;
  }
}

input[type='password'] {
  &::-ms-reveal {
    display: none;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: textfield;
}

button,
input[type='submit'] {
  display: block;
  background: transparent;
  border-radius: 0;
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none !important;
  }
}

// Modal animations
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
// End modal animations

:root {
  --dark-blue-grey: #173042;
  --ice-blue: #edf0f2;
  --robin-s-egg-blue: #9ee7fb;
  --pine-green: #081a27;
  --seafoam-blue: #5be5be;
  --very-light-blue: #dfeef2;
  --black: #121212;
  --black-two: #000000;
  --ice-blue-two: #e8f1f3;
  --pale-grey: #f4f7f9;
  --light-blue-grey: #d1d6d9;
  --light-blue-grey-two: #dfeef2;
  --danger: #f16153;
  --success: #07bc0c;
  --info: #3498db;
  --warning: #f1c40f;
  --white: #fff;
  --white40: rgba(255, 255, 255, 0.4);
  --white80: rgba(255, 255, 255, 0.8);
  --grey: #a2acb3;
  --sky: #4eb3d4;
  --dark-grey: #838383;
  --dark-grey-two: #2d353b;
  --aquamarine: #4ed4b2;
  --shadow: #c2ccd6;
  --dark-gray10: rgba(23, 48, 66, 0.1);
  --light-gray75: rgba(237, 247, 251, 0.75);
  --ice-blue75: rgba(158, 231, 251, 0.75);
  --black-two33: rgba(0, 0, 0, 0.33);
  --black-two20: rgba(0, 0, 0, 0.2);
  --dark-blue70: rgba(8, 26, 39, 0.7);
  --dark-gray50: rgba(55, 54, 54, 0.5);

  --green-box-shadow: 0 0 25px 0 rgba(74, 255, 255, 0.45);
}

.MuiOutlinedInput-notchedOutline* {
  border: 1px solid lightgrey;
  border-color: lightgrey;
  outline: none;
}
.Mui-focused fieldset {
  border: 1px solid lightgrey;
  border-color: lightgrey;
  outline: none;
}

.auth0-lock-container {
  .auth0-lock-form > div > p {
    word-break: break-word;
  }

  .auth0-lock-alternative {
    &-link {
      font-weight: normal;
    }
  }
}

.acsb-trigger.acsb-trigger-size-big {
  width: 60px !important;
  height: 60px !important;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
